import Dialog from "./dialog";

export default {
  components: {
    Dialog,
  },
  model: {
    prop: "open",
  },
  props: {
    open: {
      type: Boolean,
      required: true,
    },
    options: {
      type: Object,
      default: () => ({}),
    },
    title: {
      type: String,
    },
    isLocalStorage: {
      type: Boolean,
    },
  },
  data: () => ({
    loading: false,
    data: [],
    pageData: {
      pageIndex: 1,
      pageSize: 20,
      totalNum: 0,
    },
  }),
  watch: {
    open(now) {
      if (now) {
        this.$modal.show(this.name);
        this.fetch();
      }
    },
  },
  computed: {
    cmUrl() {
      return process.env.VUE_APP_CMURL;
    },
    previewSrcList() {
      return this.data.map(({ ImageName }) => this.handleImageSrc(ImageName));
    },
  },
  methods: {
    /**
     * 图片地址
     */
    handleImageSrc(imageName) {
      if (!this.isLocalStorage) {
        return this.cmUrl + imageName;
      } else {
        return "data:image/jepg;base64," + imageName;
      }
    },
    closed() {
      this.$emit("update:open", false);
    },
    handleSizeChange(v) {
      this.pageData.pageSize = v;
      this.fetch();
    },
    handleCurrentChange(v) {
      this.pageData.pageIndex = v;
      this.fetch();
    },
  },
};
