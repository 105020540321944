//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: "CopyText",
  props: {
    text: {
      type: String,
      default: '',
    },
  },
  data: () => ({
    timer: null,
    copied: false,
  }),
  methods: {
    async copy() {
      clearTimeout(this.timer)
      await this.$copyText(this.text)
      this.copied = true
      this.resetTimer()
    },
    resetTimer() {
      this.timer = setTimeout(() => this.copied = false, 1000 * 3)
    },
  },
}
