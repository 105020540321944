//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import XModal from "@/components/XModal";
export default {
  name: "Dialog",
  components: {
    XModal,
  },
  props: {
    name: {
      type: String,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
    width: {
      type: String,
      default: "80%",
    },
    height: {
      type: String,
      default: "80%",
    },
  },
};
